import { template as template_0afed470480e4955a1c55e256b36d448 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_0afed470480e4955a1c55e256b36d448(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
