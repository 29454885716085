import { template as template_e4a41e5d74fc43f3b456806c7c5acdc2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_e4a41e5d74fc43f3b456806c7c5acdc2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
